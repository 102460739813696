<template>
  <div>
    <div class='py-2 lg:py-8 relative'>
      <content-background-cover
        background-color='#001020'
        class='z-0'
        :top-offset='0' />
      <vod-content-vimeo
        :showing-content='selectedContent'
        :autoplay='false' />
    </div>
    <div class='mt-16 pb-64'>
      <div class='flex flex-col lg:flex-row mt-16 lg:gap-x-4'>
        <div :style='vodDetailStyle'>
          <div class='vod-title px-2 lg:px-0'>
            {{ selectedContent.title }}
          </div>
          <h5 class='vod-tag px-2 lg:px-0'>
            {{ tagsString }}
          </h5>
          <p class='px-2 lg:px-0'>
            {{ vodSpeakerString }}
          </p>
        </div>
        <div v-if='hasSimilarVods' 
             class='lg:flex-grow my-8 lg:my-0 pl-2 lg:pl-0'>
          <div class='vod-title'>Similar Vods</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import VodContentVimeo              from '@/components/contents/VodContentVimeo.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Vod',
  components: {
    VodContentVimeo,
    ContentBackgroundCover
  },
  computed: {
    ...mapState('contents', [
      'selectedContent'
    ]),
    tagsString () {
      if (this.selectedPoster && this.selectedPoster.conferenceTags) {
        let tagList =  this.selectedPoster.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
    vodDetailStyle () {
      return (this.hasSimilarVods) ? `width: 66%` : 'width: 100%;' 
    },
    vodSpeakerString () {
      return (this.selectedContent.speakers) ? this.selectedContent.speakers.map(speaker => speaker.name).join(', ') : ''
    },
    hasSimilarVods () {
      return false
    },
  },
  methods: {
    ...mapActions('contents', [
      'getSingleContent'
    ])
  },
  mounted () {
    this.getSingleContent(this.$route.query.vod_id)
  }
}
</script>

<style type='scss' scoped>
.vod-title {
  @apply text-gray-700 text-2xl font-semibold mb-4;
}

.vod-tag {
  color: #529dad;
  @apply mb-4 font-medium;
}
</style>
